import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as dayjs from 'dayjs';

import { NavButton } from './NavButton';
import { NavElement } from './NavElement';
import { mainLinks } from 'config';
import { useTrail } from 'react-spring';
import { staticData } from 'config';

import { animated } from 'react-spring';
import { Deco } from 'ui/Components/Deco';
import { AwesomeIcon } from 'ui/Components/AwesomeIcon';
import { useMouseMove, MouseParallax } from 'hooks';
import { isTouchDevice } from 'utils';
import { LanguagePicker } from './LangaugePicker';
import { DelayLinkTransition } from 'router/DelayLink';

const isTouch = isTouchDevice();

dayjs.locale('de');
let mouseEnterTimeout;
export const Nav = () => {
  const [lastLocation, setLastLocation] = useState('');
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState();
  const [activeImage, setActiveImage] = useState('');
  const [activeSub, setActiveSub] = useState('');
  const [top, setTop] = useState(true);

  const { pathname } = useLocation();

  useEffect(() => {
    // setActiveImage(
    //   mainLinks.findIndex(({ link }) => pathname.search(link) !== -1),
    // );
    // if (lastLocation && lastLocation !== pathname) {
    setOpen(false);
    setTransition(true);
    setTimeout(() => {
      setTransition(false);
    }, 900);
    // setTimeout(() => {
    //   setTransition(false);
    // }, 1000);
    // }
    // setLastLocation(pathname);
  }, [pathname, lastLocation]);

  const toggleOpen = open ? 'open' : '';

  const transitions = useTrail(mainLinks.length, {
    config: { mass: 1, tension: 500, friction: 34 },
    delay: open ? 500 : 0,
    opacity: open ? 1 : 0,
    y: open ? 0 : 20,
    from: { opacity: 0, y: 20 },
  });

  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setTop(true);
    } else {
      setTop(false);
    }
  };

  useEffect(() => {
    handleScroll();
    const ev = window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', ev);
  }, []);

  const handleRoute = (name, text, img) => {
    if (pathname === '/' || pathname === '') {
      return (
        <a
          onClick={() => setOpen(false)}
          className="link fw"
          href={name}
          style={{ textDecoration: 'none' }}
        >
          {img ? (
            img
          ) : (
            <p className="small uppercase fw-medium mobile">{text}</p>
          )}
        </a>
      );
    } else {
      return (
        <DelayLinkTransition
          onClick={() => setOpen(false)}
          className="link fw"
          style={{ textDecoration: 'none' }}
          to={'/' + name}
        >
          {img ? (
            img
          ) : (
            <p className="small uppercase fw-medium mobile">{text}</p>
          )}
        </DelayLinkTransition>
      );
    }
  };

  return (
    <>
      {window.innerWidth < 720 ? (
        <>
          <nav
            className={`${
              isTouch ? 'touch-support' : ''
            } row justify-space-between flex-align-stretch ${toggleOpen} ${
              transition ? 'anim' : ''
            }`}
          >
            <TopBar />
            <LeftBar />
            <div className="nav__content fh">
              <div className="row justify-space-around flex-align-center fw fh">
                <div
                  className={`col-md-12 col-ml-12 nav row flex-align-center`}
                >
                  {transitions.map(({ y, ...rest }, i) => {
                    return (
                      <animated.div
                        className="fw"
                        key={i}
                        style={{
                          ...rest,
                          transform: y.interpolate(
                            (y) => `translate3d(0,${y}px,0)`,
                          ),
                        }}
                      >
                        {handleRoute(mainLinks[i].link, mainLinks[i].text)}

                        {/* <NavElement
                          {...mainLinks[i]}
                          onMouseEnter={() => {
                            if (isTouch) return;
                            clearTimeout(mouseEnterTimeout);
                            mouseEnterTimeout = setTimeout(() => {
                              setActiveImage(i);
                            }, 200);
                            if (!mainLinks[i].submenu) return;
                            setActiveSub(i);
                          }}
                          onClick={(e) => {
                            // if (!isTouch) return;
                            // setActiveImage(i);
                            // if (!mainLinks[i].submenu) return;
                            if (activeSub === i) return;
                            e.preventDefault();
                            setActiveSub(i);
                          }}
                          activeSub={activeSub === i}
                        /> */}
                      </animated.div>
                    );
                  })}
                </div>
                <NavDeco activeImage={activeImage} open={open} />
              </div>
            </div>
          </nav>
          <NavButton
            className={`${toggleOpen}`}
            onClick={() => setOpen(!open)}
          />
        </>
      ) : (
        <div
          className={`${top ? 'top-nav' : 'top-nav-n'} pl-3 pr-3 fw `}
          style={{
            zIndex: 50,
            position: 'fixed',
            transition: '200ms',
            // background: 'rgba(255,255,255,0.5)',
          }}
        >
          <div
            style={{
              zIndex: 50,
            }}
            className="nn fw black row justify-space-between flex-align-center pt-4 pb-2"
          >
            <div>
              {handleRoute(
                '#',
                '',
                <img
                  alt=""
                  src="/assets/viva.svg"
                  style={{ maxWidth: '100px' }}
                />,
              )}
              {/* <p className="small fw-medium uppercase">Viva Apartments</p> */}
            </div>
            <div className="row flex-align-center">
              <div className="pl-2">
                {handleRoute('#penthouse', 'Penthouse')}
              </div>
              <div className="pl-2">
                {handleRoute('#apartments', 'Apartments')}
              </div>
              <div className="pl-2">
                {handleRoute('#austattung', 'Ausstattung')}
              </div>
              <div className="pl-2">{handleRoute('#services', 'Services')}</div>
              <div className="ml-2 sp-btn">
                {handleRoute('#reservieren', 'Reservieren')}
              </div>
              <div className="ml-2">{handleRoute('/help', 'Help')}</div>

              <div className="pl-4">
                <a
                  href="tel:+43 664 455 66 09"
                  className="link"
                  style={{ textDecoration: 'none' }}
                >
                  <AwesomeIcon icon="phone" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const NavDeco = ({ activeImage, open }) => {
  const [xy] = useMouseMove({ speed: 3, stop: !open });

  return (
    <div className="col-md-6 col-ml-7 nop deco" style={{ zIndex: 2 }}>
      <MouseParallax
        style={{ width: '100%', height: '100%', position: 'absolute' }}
        xy={xy}
        speed={5}
      >
        <Deco width={9} height={5} style={{ bottom: -80, right: -100 }} />
      </MouseParallax>
      <MouseParallax xy={xy} speed={10}>
        <Deco width={4} height={8} style={{ top: -80, left: -40 }} />
      </MouseParallax>
      <MouseParallax xy={xy} speed={0}>
        <div className="image-container">
          {mainLinks.map(({ img }, i) => (
            <div key={i} className={`img ${i === activeImage ? 'active' : ''}`}>
              <img alt="" src={img} />
            </div>
          ))}
        </div>
      </MouseParallax>
      <MouseParallax xy={xy} speed={12}>
        <div className="aventa-strokes"></div>
      </MouseParallax>
    </div>
  );
};

const LeftBar = () => {
  return (
    <div className="left-bar column ">
      {/* <a
        className="simple-hover"
        target="_blank"
        rel="noopener noreferrer"
        href="# "
      >
        <AwesomeIcon prefix="fab" icon="twitter" />
      </a>
      <a className="icon mt-x4 simple-hover" href="# ">
        <AwesomeIcon prefix="fab" icon="instagram" />
      </a>
      <a
        className="icon mt-x4 simple-hover"
        href="https://www.linkedin.com/company/aventa-immobilien-gmbh/?originalSubdomain=at"
      >
        <AwesomeIcon prefix="fab" icon="linkedin" />
      </a>
      <a
        className="icon mt-x4 simple-hover"
        href="https://www.facebook.com/aventaimmobilien/"
      >
        <AwesomeIcon prefix="fab" icon="facebook-square" />
      </a> */}
    </div>
  );
};

const TopBar = () => {
  const { email, tel } = staticData;

  return (
    <div className="top-bar fw row flex-align-center justify-space-between">
      <DelayLinkTransition
        to="/"
        className="column logo-link justify-center flex-aign-center"
      >
        <img
          style={{ width: '100px' }}
          className="logo-icon"
          src="/assets/viva.svg"
          alt="some logo"
        />
      </DelayLinkTransition>
      <div className="contact-info row justify-space-between">
        <div>
          <a className="simple-hover mr-2" href={tel.link}>
            <AwesomeIcon className="mr-1" icon="phone" />
            <span>{tel.text}</span>
          </a>
          <a
            className="simple-hover mr-2"
            href={email.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            <AwesomeIcon className="mr-1" prefix="far" icon="envelope" />
            <span>{email.text}</span>
          </a>
        </div>
        {/* <LanguagePicker /> */}
      </div>
    </div>
  );
};
