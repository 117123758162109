import { useParallax } from 'components/Parallax/ParallaxContext';
import React from 'react';
import { withRouter } from 'react-router-dom';
const ScrollRestoration = ({ children, location: { pathname } }) => {
  const { setOffset } = useParallax();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setOffset({ offset: 0 });
    setTimeout(() => {
      setOffset({ offset: 0 });
    }, 200);
  }, [pathname, setOffset]);

  return children || null;
};

export default withRouter(ScrollRestoration);
