import React from 'react';
import { useLoaded } from './LoaderContext';

export const LoaderTransition = ({ children, style, ...props }) => {
  const loaded = useLoaded();
  return (
    <div
      {...props}
      style={{
        ...style,
        transform: loaded ? 'scale(1)' : 'scale(1.3)',
        transition: '0.7s cubic-bezier(0.65, 0, 0.35, 1)',
        transitionDelay: loaded ? '0s' : '0s',
      }}
    >
      {children}
    </div>
  );
};
