import React, { useEffect, useContext } from 'react';
import { useSpring } from 'react-spring';
import throttle from 'lodash/throttle';
import { useIsMobile } from 'hooks';
import { isIE } from 'utils';

export const ParallaxContext = React.createContext();
export const useParallax = () => useContext(ParallaxContext);

export const ParallaxProvider = ({ children }) => {
  const isMobile = useIsMobile();
  const [{ offset }, set] = useSpring(() => ({
    offset: 0,
    config: { mass: 1, tension: 170, friction: 26 },
  }));

  const handleScroll = () => {
    const offset = window.pageYOffset;
    set({ offset });
  };

  useEffect(() => {
    if (isMobile || isIE()) return set({ offset: 0 });
    const throttledFunction = throttle(handleScroll, 150);
    window.addEventListener('scroll', throttledFunction);

    return () => {
      window.removeEventListener('scroll', throttledFunction);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <ParallaxContext.Provider value={{ offset, isMobile, setOffset: set }}>
      {children}
    </ParallaxContext.Provider>
  );
};
