import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Nav } from './Nav';
import { Footer } from './Footer';

// Lazy import page components
const Home = React.lazy(() => import('../pages/Home'));

const Immobilien = React.lazy(() => import('../pages/Immobilien'));
const ImmobilienSingle = React.lazy(() => import('../pages/ImmobilienSingle'));
const Akquisition = React.lazy(() => import('../pages/Immobilien/Akquisition'));
const Projekte = React.lazy(() => import('../pages/Immobilien/Projekte'));
const Referenzen = React.lazy(() => import('../pages/Immobilien/Referenzen'));

const Help = React.lazy(() => import('../pages/Help'));

const Impressum = React.lazy(() => import('../pages/Impressum'));
const Datenschutz = React.lazy(() => import('../pages/Datenschutz'));
const Error404 = React.lazy(() => import('../pages/Error404'));

const MainRouter = () => {
  return (
    <>
      <Suspense fallback={null}>
        <Nav />
        {/* <StockMarketBar /> */}
      </Suspense>

      <Suspense fallback={null}>
        <div className="main-content">
          {/* prettier-ignore */}
          <Switch>
            <Route exact path="/" component={Home} />
            {/* Unternehmen Routes */}
            {/* <Route exact path="/unternehmen" component={Unternehmen} /> */}
            <Route exact path="/help" component={Help} />
            {/* Immobilien Routes */}
            <Route exact path="/apartment" component={Immobilien} />
            <Route exact path="/apartment/akquisition" component={Akquisition} />
            <Route exact path="/apartment/projekte" component={Projekte} />
            <Route exact path="/apartment/referenzen" component={Referenzen} />
            <Route exact path="/apartment/:slug" component={ImmobilienSingle} />
      
            {/* Legal Routes */}
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/datenschutz" component={Datenschutz} />

            <Route component={Error404} />
          </Switch>

          <Footer />
        </div>
      </Suspense>
    </>
  );
};

export default MainRouter;
