import React from 'react';
import PropTypes from 'prop-types';
import { SimpleParallax } from 'components/Parallax/Parallax';

export const Deco = ({
  style,
  width,
  height,
  parallax,
  className = '',
  ...props
}) => {
  if (parallax)
    return (
      <div style={{ position: 'absolute', ...style }}>
        <SimpleParallax speed={parallax}>
          <div
            {...props}
            className={`bg-triangle ${className}`}
            style={{
              width: `${width * 40}px`,
              height: `${height * 40}px`,
              position: 'absolute',
              opacity: '.2',
              backgroundImage: "url('/assets/images/triangle.png')",
              ...style,
            }}
          ></div>
        </SimpleParallax>
      </div>
    );
  return (
    <div
      {...props}
      className={`bg-triangle ${className}`}
      style={{
        width: `${width * 40}px`,
        height: `${height * 40}px`,
        position: 'absolute',
        opacity: '.2',
        backgroundImage: "url('/assets/images/triangle.png')",
        ...style,
      }}
    ></div>
  );
};

export const DecoText = ({
  style,
  width,
  height,
  parallax,
  className = '',
  ...props
}) => {
  if (parallax)
    return (
      <div style={{ position: 'absolute', ...style }}>
        <SimpleParallax speed={parallax}>
          <div {...props} className={`${className}`}></div>
        </SimpleParallax>
      </div>
    );
  return (
    <div
      {...props}
      className={`${className}`}
      style={{
        position: 'absolute',
        ...style,
      }}
    ></div>
  );
};

Deco.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};
