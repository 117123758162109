export * from './svgTransformOrigin';
export * from './local-storage';

export function isIE() {
  var ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  return is_ie;
}

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

const _pipe = (a, b) => (arg) => b(a(arg));
export const pipe = (...ops) => ops.reduce(_pipe);

// TODO descripe what dis shit does OWO
export const mapToObject = (array, fnc, key) => {
  const obj = {};
  for (const item of array) {
    obj[item[key]] = fnc(item);
  }

  return obj;
};

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// @TODO Maybe this isnt needed but i still but it here
// its for deeper dynamic forms

// o -> takes an Object
// value -> is the new value for the key
// depth -> array of strings / The key that gets the new value
// n -> auto assigned value to iterate // is not needed
export const iterateObject = (o, value, depth, n) => {
  let i = n || 0;
  return Object.keys(o).forEach((k) => {
    if (k === depth[i]) {
      if (i === depth.length - 1) {
        o[k] = value;
        return o;
      }
      i++;
      iterateObject(o[k], value, depth, i);
      return;
    }
  });
};

// simple solution (do not trust)
export const isTouchDevice = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;
