import React, { useRef, useCallback } from 'react';
import { a } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { isIE } from 'utils';

import { useParallax } from 'components/Parallax';

export const SimpleParallax = ({
  speed,
  options = { threshold: 0, rootMargin: '300px' },
  children,
  fh,
  className = '',
  ...props
}) => {
  const { offset: parallax, isMobile } = useParallax();

  const ref = useRef();

  const [inViewRef, inView] = useInView(options);

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const calc = (o) => {
    if (isIE()) return 'translateY(0)';
    return inView && !isMobile
      ? `translateY(${
          (o +
            window.innerHeight / 2 -
            (window.pageYOffset + ref?.current?.getBoundingClientRect()?.top) -
            ref?.current?.getBoundingClientRect().height / 2) *
          0.2 *
          speed
        }px)`
      : 'translateY(0)';
  };

  if (isIE()) return <div className={className}>{children}</div> || null;

  return (
    // be aware this div is not useless without it everything goes brrrrrrrrrr
    <div
      ref={setRefs}
      {...props}
      className={fh ? `fh ${className}` : className}
    >
      <a.div
        style={{
          transform: parallax.interpolate(calc),
          height: fh ? '100%' : 'auto',
        }}
      >
        {children}
      </a.div>
    </div>
  );
};

export const ParallaxSection = ({
  speed,
  image,
  imgClass = '',
  imgStyle = {},
  video,
  xClass,
  options = { threshold: 0, rootMargin: '300px' },
  children,
}) => {
  const { offset: parallax, isMobile } = useParallax();
  const ref = useRef();

  const [inViewRef, inView] = useInView(options);
  const [fadeInViewRef, fadeInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const calc = (o) => {
    if (isIE()) return 'translateY(0)';
    return inView && !isMobile
      ? `translateY(${
          (o -
            (window.pageYOffset + ref.current.getBoundingClientRect().top) +
            ref.current.getBoundingClientRect().height / 2) *
          0.5 *
          speed
        }px)`
      : 'translateY(0)';
  };

  return (
    <div
      className={`${xClass} overflow-hidden parallax-section ${
        fadeInView ? 'anim' : ''
      }`}
      ref={setRefs}
    >
      <a.div
        style={{
          ...imgStyle,
          width: '100%',
          height: '100%',
          position: 'absolute',
          transform: parallax.interpolate(calc),
        }}
      >
        {image ? (
          <img
            className={imgClass}
            ref={fadeInViewRef}
            alt=""
            style={{
              objectFit: 'cover',
              width: '100%',
              height: 'auto',
              minHeight: '120%',

              // transform: 'scale(1.2)',
            }}
            src={image}
          />
        ) : video ? (
          <video
            ref={fadeInViewRef}
            playsinline
            loop
            autoPlay
            muted
            style={{
              objectFit: 'cover',
              width: '100%',
              height: 'auto',
              minHeight: '120%',
              // transform: 'scale(1.2)',
            }}
            src={video}
          />
        ) : null}
      </a.div>
      {children}
    </div>
  );
};
