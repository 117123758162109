// example custom icon
// export const faChevronRight = {
//   icon: [
//     256, // viewPort width
//     512, // viewPort height
//     [], // doesnt matter
//     'f054', // id
//     'M17.525 36.465l-7.071z', // path data
//   ],
//   iconName: 'chevron-right', // icon name
//   prefix: 'fac', // prefix
// };

export const faEnvelopeRegular = {
  icon: [
    512,
    512,
    [],
    'f054',
    'M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z',
  ],
  iconName: 'envelope',
  prefix: 'far',
};

export const faLongArrowAltRight = {
  icon: [
    448,
    512,
    [],
    'f554',
    'M340.485 366l99.03-99.029c4.686-4.686 4.686-12.284 0-16.971l-99.03-99.029c-7.56-7.56-20.485-2.206-20.485 8.485v71.03H12c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h308v71.03c0 10.689 12.926 16.043 20.485 8.484z',
  ],
  iconName: 'long-arrow-alt-right',
  prefix: 'far',
};

export const faLongArrowAltLeft = {
  icon: [
    448,
    512,
    [],
    'f555',
    'M107.515 150.971L8.485 250c-4.686 4.686-4.686 12.284 0 16.971L107.515 366c7.56 7.56 20.485 2.206 20.485-8.485v-71.03h308c6.627 0 12-5.373 12-12v-32c0-6.627-5.373-12-12-12H128v-71.03c0-10.69-12.926-16.044-20.485-8.484z',
  ],
  iconName: 'long-arrow-alt-left',
  prefix: 'far',
};
export const farCalendarCheck = {
  icon: [
    448,
    512,
    [],
    'f555',
    'M400 64h-48V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H160V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V160h352v298a6 6 0 0 1-6 6zm-52.849-200.65L198.842 404.519c-4.705 4.667-12.303 4.637-16.971-.068l-75.091-75.699c-4.667-4.705-4.637-12.303.068-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l44.104 44.461 111.072-110.181c4.705-4.667 12.303-4.637 16.971.068l22.536 22.718c4.667 4.705 4.636 12.303-.069 16.97z',
  ],
  iconName: 'calendar-check',
  prefix: 'far',
};

export const faAventa = {
  icon: [
    511.72,
    491.37,
    [],
    'f234',
    'M0,486,245.5,0,458.43,428.39s-60.13-62.65-115.24-60.12L247.43,186S145.11,381.78,146.7,395.48s63.64,4.84,157.11,0S498,484.92,511.72,491.37C353,389.06,87.87,511.53,0,486',
  ],
  iconName: 'aventa',
  prefix: 'far',
};

export const faReports = {
  icon: [
    511.72,
    491.37,
    [],
    'f231',
    'M378.66,7.5V62.15a7.5,7.5,0,1,1-15,0V15H15V363.76a7.5,7.5,0,0,1-15,0V7.5A7.5,7.5,0,0,1,7.5,0H371.16A7.5,7.5,0,0,1,378.66,7.5Zm-7.5,82.15a7.5,7.5,0,0,0-7.5,7.5V497H15V398.76a7.5,7.5,0,0,0-15,0V504.5A7.5,7.5,0,0,0,7.5,512H371.16a7.5,7.5,0,0,0,7.5-7.5V97.15A7.5,7.5,0,0,0,371.16,89.65ZM90.33,98a58,58,0,1,1-58,58A58.07,58.07,0,0,1,90.33,98Zm42.34,65.5H90.33a7.5,7.5,0,0,1-7.5-7.5V113.65a43,43,0,1,0,49.84,49.84Zm-34.84-15h34.84a43.1,43.1,0,0,0-34.84-34.84ZM92.35,432.35a7.5,7.5,0,0,1,7.5,7.5v32.32a7.49,7.49,0,0,1-7.5,7.5H43.87a7.49,7.49,0,0,1-7.5-7.5V439.85a7.5,7.5,0,0,1,7.5-7.5Zm-7.5,15H51.37v17.32H84.85Zm32.33,24.82V391.36a7.5,7.5,0,0,1,7.5-7.5h48.49a7.5,7.5,0,0,1,7.5,7.5v80.81a7.5,7.5,0,0,1-7.5,7.5H124.68A7.5,7.5,0,0,1,117.18,472.17Zm15-7.5h33.49V398.86H132.18ZM254,323.25a7.5,7.5,0,0,1,7.5,7.5V472.17a7.5,7.5,0,0,1-7.5,7.5H205.49a7.5,7.5,0,0,1-7.5-7.5V330.75a7.5,7.5,0,0,1,7.5-7.5Zm-7.5,15H213V464.67h33.49Zm88.31-13.95a7.5,7.5,0,0,0,7.5-7.5V290.35a7.5,7.5,0,0,0-7.5-7.5H286.31a7.5,7.5,0,0,0-7.5,7.5V472.17a7.5,7.5,0,0,0,7.5,7.5h48.48a7.5,7.5,0,0,0,7.5-7.5V351.8a7.5,7.5,0,0,0-15,0V464.67H293.81V297.85h33.48V316.8A7.5,7.5,0,0,0,334.79,324.3ZM277.64,39.83V72.15a7.5,7.5,0,0,1-7.5,7.5H108.52a7.5,7.5,0,0,1-7.5-7.5V39.83a7.49,7.49,0,0,1,7.5-7.5H270.14A7.49,7.49,0,0,1,277.64,39.83Zm-15,7.5H116V64.65H262.64ZM338.83,98H189.33a7.5,7.5,0,0,0,0,15h149.5a7.5,7.5,0,0,0,0-15Zm0,33.67H189.33a7.5,7.5,0,0,0,0,15h149.5a7.5,7.5,0,0,0,0-15Zm7.5,41.17a7.5,7.5,0,0,0-7.5-7.5H189.33a7.5,7.5,0,0,0,0,15h149.5A7.5,7.5,0,0,0,346.33,172.83Zm-60.6,33.67a7.5,7.5,0,0,0-7.5-7.5h-88.9a7.5,7.5,0,0,0,0,15h88.9A7.5,7.5,0,0,0,285.73,206.5Zm-96.4,42H53a7.5,7.5,0,1,0,0,15H189.33a7.5,7.5,0,1,0,0-15Zm7.5,41.17a7.5,7.5,0,0,0-7.5-7.5H53a7.5,7.5,0,1,0,0,15H189.33A7.5,7.5,0,0,0,196.83,289.67Zm-60.61,33.67a7.5,7.5,0,0,0-7.5-7.5H53a7.5,7.5,0,1,0,0,15h75.76A7.5,7.5,0,0,0,136.22,323.34Z',
  ],
  iconName: 'reports',
  prefix: 'far',
};

export const faChevronLeft = {
  icon: [
    256,
    512,
    [],
    'f231',
    'M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z',
  ],
  iconName: 'chevron-left',
  prefix: 'fac',
};
export const faChevronRight = {
  icon: [
    256,
    512,
    [],
    'f231',
    'M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z',
  ],
  iconName: 'chevron-right',
  prefix: 'fac',
};
