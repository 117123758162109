import React from 'react';
import { useTranslation } from 'react-i18next';

import { mainLinks } from 'config';
import { staticData } from 'config';

import { DelayLinkTransition } from 'router/DelayLink';
import { useSpring, animated } from 'react-spring';
import { isIE } from 'utils';
import { AwesomeIcon } from 'ui/Components/AwesomeIcon';

const FooterLines = () => {
  // useEffect(() => {
  //   const lines = document.querySelectorAll('.footer-lines .line');
  //   for (let i = 0; i < lines.length; i++) {
  //     const el = lines[i];
  //   }
  // }, []);

  return (
    <svg
      className="footer-lines"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1007.356 723.407"
    >
      <g transform="translate(3414.452 -6060.912)" opacity="0.3">
        <path
          className="line"
          d="M-3413.952,6061.912v496.575H-2407.6V6061.912"
          transform="translate(0 -1)"
          strokeDasharray={1999.502197265625}
          strokeDashoffset="1999.502197265625"
        />
        <path
          className="line"
          d="M-2910.774,6060.912v350.468h100.78V6060.912"
          strokeDasharray={801.715576171875}
          strokeDashoffset="801.715576171875"
        />
        <path
          className="line"
          d="M-3213.207,6784.318V6260.009l587.285-4.137v524.309"
          strokeDasharray={1635.917724609375}
          strokeDashoffset="1635.917724609375"
        />
        <path
          className="line"
          d="M-3310.937,6668.1V6149.072h805.613v217.912h-204.7V6668.1Z"
          strokeDasharray={2649.28271484375}
          strokeDashoffset="2649.28271484375"
        />
      </g>
    </svg>
  );
};

const calc = (x, y, p, divisor) => [
  -(y - window.innerHeight / 2) / divisor,
  (x - window.innerWidth / 2) / divisor,
  p,
];

const trans = (x, y) =>
  `perspective(1000px) translateX(${-y * 4}px) rotateY(${y * 1.5}deg)`;

export const Footer = () => {
  const [t] = useTranslation(['home', 'translations']);
  const IS_IE = isIE();
  const { email, tel } = staticData;

  const [spring, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 1, tension: 50, friction: 14 },
  }));

  return (
    <footer
      className="footer pt-2 pt-md-20"
      onMouseMove={({ clientX: x, clientY: y }) => {
        if (IS_IE) return;
        set({ xys: calc(x, y, 1000, 50) });
      }}
      onMouseLeave={() => set({ xys: [0, 0, 1000] })}
      // style={{
      //   backgroundImage: 'url("/assets/images/footer-lines.svg")',
      //   backgroundSize: 'contain',
      //   backgroundPosition: 'center',
      //   backgroundRepeat: 'no-repeat',
      // }}
    >
      {/* <FooterLines /> */}
      <div className="row container-big justify-center pb-2 pb-md-20 overflow-hidden">
        <DelayLinkTransition to="/">
          <animated.img
            className="logo p-3"
            src={'/assets/viva.svg'}
            alt="Aventa logo"
            style={{ transform: spring.xys.interpolate(trans) }}
          />
        </DelayLinkTransition>
      </div>
      {/* <div
        className="row container-middle-big justify-space-around pb-x10"
        style={{ color: '#212121' }}
      >
        {mainLinks.map(({ link, text }) => (
          <p key={text} className="small fw-black uppercase ml-x1 mr-x1">
            <DelayLinkTransition to={link} className="link">
              {text}
            </DelayLinkTransition>
          </p>
        ))}
      </div> */}
      <div
        className="row container justify-space-between flex-align-center pl-x3 pr-x3 pb-x2"
        style={{ color: '#212121' }}
      >
        {/* <div className="flexbox flex-align-center">
          <a className="simple-hover  mr-x4" href={tel.link}>
            <AwesomeIcon className="mr-x1 accent" icon="phone" />
            <span>{tel.text}</span>
          </a>
          <a
            className="simple-hover mr-x4"
            href={email.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            <AwesomeIcon
              className="mr-x1 accent"
              prefix="far"
              icon="envelope"
            />
            <span>{email.text}</span>
          </a>
        </div> */}

        {/* <div>
          <DelayLinkTransition
            to="/investor-relations"
            className="btn-outlined uppercase mt-x2"
            style={{ color: '#212121' }}
          >
            {t('Investiere mit Aventa')}
          </DelayLinkTransition>
        </div> */}
      </div>
      <div className="bg-gray-100 copyright">
        <div className="container" style={{ color: '#212121' }}>
          <div className="row justify-space-between flex-align-center pl-3 pr-3 pt-3 pb-3">
            <p className="nomb">
              Copyright © {new Date().getFullYear()} VIVA
            </p>
            <div className="flexbox">
              <DelayLinkTransition to="/impressum" className="link mr-4 nomb">
                {t('Impressum')}
              </DelayLinkTransition>
              <p className="mr-4 nomb">|</p>
              <DelayLinkTransition to="/datenschutz" className="link nomb">
                {t('Datenschutz')}
              </DelayLinkTransition>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
