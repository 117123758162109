export const mainLinks = [
  {
    link: '#',
    text: 'Home',
  },
  {
    link: '#penthouse',
    text: 'Penthouse',
  },
  {
    link: '#apartments',
    text: 'Apartments',
  },
  {
    link: '#austattung',
    text: 'ausstattung',
  },
  {
    link: '#services',
    text: 'Services',
  },
  {
    link: '#reservieren',
    text: 'Reservieren',
  },
  {
    link: '/help',
    text: 'Help',
  },
];

export const legalLinks = [
  { link: '/impressum', text: 'Impressum' },
  { link: '/datenschutz', text: 'Datenschutz' },
];
