import { useEffect } from 'react';
import { interpolate, useSpring, animated } from 'react-spring';
import { isIE } from 'utils';

export const useMouseMove = ({ config = {}, speed = 2, stop }) => {
  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    config,
  }));

  useEffect(() => {
    if (isIE()) return;
    const onMove = ({ clientX: x, clientY: y }) => {
      if (stop) return;
      set({
        xy: [
          (x - window.innerWidth / 2) / speed,
          (y - window.innerHeight / 2) / speed,
        ],
      });
    };

    window.addEventListener('mousemove', onMove);
    return () => {
      window.removeEventListener('mousemove', onMove);
    };
    // eslint-disable-next-line
  }, [stop]);

  return [xy, interpolate];
};

export const MouseParallax = ({
  xy,
  speed = 1,
  children,
  style = {},
  ...props
}) => {
  const transform = (x, y) => `translate3d(${x / speed}px,${y / speed}px,0)`;
  if (!xy) return children;
  return (
    <animated.div
      {...props}
      style={{ ...style, transform: xy.interpolate(transform) }}
    >
      {children}
    </animated.div>
  );
};
