import React from 'react';
import PropTypes from 'prop-types';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faPhone,
  faPhoneSquareAlt,
  faBriefcase,
  faBars,
  faChevronDown,
  faHeartbeat,
  faHome,
  faCar,
  faCalendarCheck,
  faCaretRight,
  faCaretLeft,
  faPlay,
  faCheck,
  faVolumeUp,
  faVolumeMute,
  faPause,
  faDownload,
  faMobileAlt,
  faSubway,
  faUtensils,
  faCalendarAlt,
  faAppleAlt,
  faHandHoldingMedical,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import {
  faInstagram,
  faTwitter,
  faFacebookSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelopeRegular,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faAventa,
  farCalendarCheck,
  faReports,
  faChevronRight,
  faChevronLeft,
} from './custom-icons';

library.add(
  faEnvelopeRegular,
  faInstagram,
  faTwitter,
  faFacebookSquare,
  faPhone,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLinkedin,
  faBriefcase,
  faBars,
  faChevronDown,
  faHeartbeat,
  faHome,
  faCar,
  faCalendarCheck,
  faCaretRight,
  faCaretLeft,
  faChevronRight,
  faChevronLeft,
  faPlay,
  faAventa,
  faCheck,
  farCalendarCheck,
  faVolumeUp,
  faVolumeMute,
  faPause,
  faDownload,
  faReports,
  faMobileAlt,
  faSubway,
  faUtensils,
  faCalendarAlt,
  faAppleAlt,
  faHandHoldingMedical,
  faChevronRight,
  faChevronLeft,
  faPhone,
  faEnvelope,
  faPhoneSquareAlt
);

export const AwesomeIcon = ({ ...props }) => {
  return <FontAwesomeIcon {...props} icon={[props.prefix, props.icon]} />;
};

AwesomeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

AwesomeIcon.defaultProps = {
  prefix: 'fas',
};
