export const staticData = {
  companyName: 'VIVA',
  email: {
    link: 'mailto:office@viva-apartments.at',
    text: 'office@viva-apartments.at',
    target: '__blank',
  },
  tel: {
    link: 'tel:+43 664 455 66 09',
    text: '+43 664 455 66 09',
  },
  address: ['Pomisgasse 23, 8010 Graz'],
  website: {
    link: 'https://www.viva.at',
    text: 'www.viva.at',
  },
  // facebookLink: 'https://www.facebook.com/Lorem Ipsum/',
};
