import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'intersection-observer';
import './scss/index.min.css';

import objectFitImages from 'object-fit-images';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

import 'config/i18n';
import 'dayjs/locale/de';

import { BrowserRouter } from 'react-router-dom';

import { ParallaxProvider } from 'components/Parallax';

import { isIE, isSafari } from 'utils';
import MainRouter from './router';
import ScrollRestoration from './router/scrollRestoration';
import { LoaderContextProvider } from 'components/Loader';
import { CookieContextProvider } from 'components/CookiePopup/CookieContext';

if (isIE()) {
  document.body.classList.add('is-ie');
  objectFitImages();
}

if (isSafari) {
  document.body.classList.add('is-safari');
}

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ParallaxProvider>
      <ScrollRestoration>
        <LoaderContextProvider>
          <CookieContextProvider>
            <MainRouter />
          </CookieContextProvider>
        </LoaderContextProvider>
      </ScrollRestoration>
    </ParallaxProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
